import React from "react";

const SearchMap = () => {
   
  return (
    <>
    <section className="search_map">
        <div className="contain-fluid fix_cell_result_lbl">
            <p>10,325 properties available</p>
        </div>
        <div id="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.46795716484!2d72.68540231452256!3d32.08363608118693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392177c8976de103%3A0xfd13b9e98e36a9fe!2sHero+Solutions!5e0!3m2!1sen!2s!4v1565165927597!5m2!1sen!2s" width="100%" frameBorder="0" allowFullScreen></iframe>
        </div>
    </section>
    </>
  );
};

export default SearchMap;
