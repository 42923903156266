import React,{useState} from "react";
import { NavLink,Link } from "react-router-dom";
import {useLocation } from "react-router-dom";
const OwnerFilter = () => {
    const[contact,setContact] = useState(false);
    const ToggleContact = () => {
        setContact(!contact);
    }
  const location = useLocation();
  const location_path = location.pathname;
  const current_page = location_path.split('/')[1];
  return (
    <>
    <div className="result_filter">
        <div className="contain-fluid">
            <div className="filter_result_flex detail_filter_result_flex">
                <div className="_col">
                    <div className="filter_blk">
                        <div className="text_search text_new_search">
                            <div className="main_filter_title">
                                <h4>Thomas Rentals L.L.C.</h4>
                                <p>& 10,325 properties available</p>
                            </div>
                        </div>
                        <NavLink to="/owner-overview" className={current_page=='owner-overview' ? "site_btn" : "site_btn blank yellow_blank"}>Overview</NavLink>
                        <NavLink to="/owner-property" className={current_page=='owner-property' ? "site_btn" : "site_btn blank yellow_blank"}>Properties</NavLink>
                        <NavLink to="/owner-companies" className={current_page=='owner-companies' ? "site_btn" : "site_btn blank yellow_blank"}>Comapnies</NavLink>
                    </div>
                </div>
                <div className="_col">
                    <div className="filter_blk drop_result_btn">
                        <button class="filter_btn site_btn view_searches_btn" onClick={ToggleContact}><img src="/images/contact_book.svg" alt="" /><span>Contact</span></button>
                        <div className={contact ? "drop_cnt medium_medium owner_filter_cnt_drop active" : "drop_cnt owner_filter_cnt_drop medium_medium"}>
                            <div className="contact_drop_inner">
                                <div className="flex_info">
                                    <div className="dp">
                                        <img src="/images/profile.svg" alt="" />
                                    </div>
                                    <div className="cntnt">
                                        <h5>Matt Abedin</h5>
                                        <p>Owns 34 properties | Related to Lubin Associates No. 2, LLC +2 more companies | west hartford, CT</p>
                                    </div>
                                </div>
                                <div className="flex_flex head_flex">
                                    <div className="col">
                                        <p>Phone numbers</p>
                                    </div>
                                    <div className="col">
                                        <p>Emails and addresses</p>
                                    </div>
                                </div>
                                <div className="flex_flex">
                                    <div className="col">
                                        <p className="hide_big_sc">Phone numbers</p>
                                        <ul>
                                            <li>
                                                <div className="left_cnt">
                                                    <div className="social_icon">
                                                        <img src="/images/dark_call.svg" alt="" />
                                                    </div>
                                                    <div className="cn_tnt">
                                                        <h5>170-890-0988</h5>
                                                        <p>R L Marketing</p>
                                                    </div>
                                                </div>
                                                <div className="right_cnt">
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini_call.svg" alt="" />
                                                    </Link>
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini-star.svg" alt="" />
                                                    </Link>
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini-bookmark.svg" alt="" />
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left_cnt">
                                                    <div className="social_icon">
                                                        <img src="/images/dark_call.svg" alt="" />
                                                    </div>
                                                    <div className="cn_tnt">
                                                        <h5>123-890-4548</h5>
                                                        <p>Maerican mortage corp</p>
                                                    </div>
                                                </div>
                                                <div className="right_cnt">
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini_call.svg" alt="" />
                                                    </Link>
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini-star.svg" alt="" />
                                                    </Link>
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini-bookmark.svg" alt="" />
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left_cnt">
                                                    <div className="social_icon">
                                                        <img src="/images/dark_call.svg" alt="" />
                                                    </div>
                                                    <div className="cn_tnt">
                                                        <h5>130-220-2384</h5>
                                                        <p>Sales Marketing</p>
                                                    </div>
                                                </div>
                                                <div className="right_cnt">
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini_call.svg" alt="" />
                                                    </Link>
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini-star.svg" alt="" />
                                                    </Link>
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini-bookmark.svg" alt="" />
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left_cnt">
                                                    <div className="social_icon">
                                                        <img src="/images/dark_call.svg" alt="" />
                                                    </div>
                                                    <div className="cn_tnt">
                                                        <h5>170-890-0988</h5>
                                                        <p>Maerican mortage corp</p>
                                                    </div>
                                                </div>
                                                <div className="right_cnt">
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini_call.svg" alt="" />
                                                    </Link>
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini-star.svg" alt="" />
                                                    </Link>
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini-bookmark.svg" alt="" />
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left_cnt">
                                                    <div className="social_icon">
                                                        <img src="/images/dark_call.svg" alt="" />
                                                    </div>
                                                    <div className="cn_tnt">
                                                        <h5>170-890-0988</h5>
                                                        <p>R L Marketing</p>
                                                    </div>
                                                </div>
                                                <div className="right_cnt">
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini_call.svg" alt="" />
                                                    </Link>
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini-star.svg" alt="" />
                                                    </Link>
                                                    <Link to="/" className="mini_image">
                                                        <img src="/images/mini-bookmark.svg" alt="" />
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col">
                                        <p className="hide_big_sc">Emails and addresses</p>
                                        <ul>
                                            <li>
                                                <div className="left_cnt">
                                                    <div className="social_icon">
                                                        <img src="/images/dark_email.svg" alt="" />
                                                    </div>
                                                    <div className="cn_tnt">
                                                        <h5>Matt.Abedin101@gmail.com</h5>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left_cnt">
                                                    <div className="social_icon">
                                                        <img src="/images/dark_address.svg" alt="" />
                                                    </div>
                                                    <div className="cn_tnt">
                                                        <h5>79 Street Robin Rd, West harford, CT 9908</h5>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
    </>
  );
};

export default OwnerFilter;
