import React,{useState} from "react";

const ResultFilters = () => {
    const[report,setReport] = useState(false);
    const[sort,setSort] = useState(false);
    const ToggleReport = () => {
        setReport(!report);
    }
    const ToggleSort = () => {
        setSort(!sort);
    }
  return (
    <>
    <div className="result_filter">
        <div className="contain-fluid">
            <div className="filter_result_flex">
                {/* <div className="_col">
                    <div className="main_filter_title">
                        <h4>303 Park St, Atlanta, TX 75551</h4>
                        <p>& 10,325 properties available</p>
                    </div>
                </div> */}
                <div className="_col">
                    <div className="filter_blk">
                        <button className="site_btn">Properties</button>
                        <button className="site_btn blank yellow_blank">Owners</button>
                        <div class="drop_filter">
                            <button class="filter_btn site_btn blank yellow_blank"><span>Label</span><img src="/images/label_circle.svg" alt="" /></button>
                        </div>
                        <div class="drop_filter drop_result_btn">
                            <button class="filter_btn site_btn blank yellow_blank" onClick={ToggleReport}><span>Report</span><img src="/images/report_circle.svg" alt="" /></button>
                            <div className={report ? "drop_cnt medium active" : "drop_cnt medium"}>
                                <div className="header_text text-center">
                                    <h5>Print properties upto</h5>
                                </div>
                                <form action="">
                                    <div className="form_blk">
                                        <h6>Report title</h6>
                                        <input type="text" className="input" placeholder="Title"/>
                                    </div>
                                    <div className="form_blk">
                                        <div className="inner text-center">
                                            <div className="carbe_icon">
                                                <img src="/images/carbon_upload.svg" alt="" />
                                            </div>
                                            <h6>Choose logo</h6>
                                        </div>
                                    </div>
                                    <button className="site_btn" type="button">Create report</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="_col">
                    <div className="filter_blk">
                        <div class="drop_filter drop_result_btn cell_top_high_mrgn">
                            <button class="filter_btn site_btn blank yellow_blank" onClick={ToggleSort}><span>Sort by: Year renovated</span><img src="/images/filter_circle.svg" alt="" /></button>
                            <div className={sort ? "drop_cnt exact_wide active" : "drop_cnt exact_wide"}>
                               <div className="option_lst">
                                    <button>Building Area</button>
                                    <button className="active">Property Type</button>
                                    <button>Lot Area</button>
                                    <button>Mortage amount</button>
                                    <button>Last Mortagege date</button>
                                    <button>Last Sale Date</button>
                                    <button>Standardized Lender</button>
                                    <button>Sales Price</button>
                                    <button>Sales Price/SF Building areas</button>
                                    <button>Sales Price//Acre</button>
                                    <button>Total Units</button>
                                    <button>Total Assessed Value</button>
                                    <button>Year Built</button>
                                    <button>Year Renovated</button>
                                    <button>Likely to Sell</button>
                                    <button>Tax Year</button>
                                    <button>Tax Amount</button>
                                    <button>Tax Percent</button>
                                    <button>YoY Change in Tex Amount</button>
                                    <button>Viewed Date</button>
                                    <button>Note Created</button>
                               </div>
                            </div>
                        </div>
                        <button className="site_btn">Export List</button>
                        <button className="togg_icon_search"><img src="/images/list_icon.svg" alt="" /></button>
                        <button className="togg_icon_search active"><img src="/images/map_circle.svg" alt="" /></button>
                    </div>
                </div>
                <div className="_col">
                    <div className="filter_blk">
                        <button class="filter_btn site_btn view_searches_btn"><span>View Search</span><img src="/images/show_search_icon.svg" alt="" /></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
    </>
  );
};

export default ResultFilters;
