import React from "react";
import Filters from "./Filters";
import ResultFilters from "./Result-filter";
import ResultProperties from "./Result-properties";
import MobileAdvance from "./Mobile-advance";

const ResultSearch = () => {
  const listing = {
    properties:[
      {
        id:"1",
        price:"340k",
        date:"Debt on Jul 2022",
        title:"303 Park St, Atlanta, TX 75551",
        build:"Property built in 2020",
        owner:"Thomason Rentals Llc.",
        link:"/",
        email:"/",
        call:"/",
        beds:"3",
        baths:"4",
        square:"6x8 m²"
      },
      {
        id:"2",
        price:"340k",
        date:"Debt on Jul 2022",
        title:"303 Park St, Atlanta, TX 75551",
        build:"Property built in 2020",
        owner:"Thomason Rentals Llc.",
        link:"/",
        email:"/",
        call:"/",
        beds:"3",
        baths:"4",
        square:"6x8 m²"
      },
      {
        id:"3",
        price:"340k",
        date:"Debt on Jul 2022",
        title:"303 Park St, Atlanta, TX 75551",
        build:"Property built in 2020",
        owner:"Thomason Rentals Llc.",
        link:"/",
        email:"/",
        call:"/",
        beds:"3",
        baths:"4",
        square:"6x8 m²"
      },
      {
        id:"4",
        price:"340k",
        date:"Debt on Jul 2022",
        title:"303 Park St, Atlanta, TX 75551",
        build:"Property built in 2020",
        owner:"Thomason Rentals Llc.",
        link:"/",
        email:"/",
        call:"/",
        beds:"3",
        baths:"4",
        square:"6x8 m²"
      },
      {
        id:"5",
        price:"340k",
        date:"Debt on Jul 2022",
        title:"303 Park St, Atlanta, TX 75551",
        build:"Property built in 2020",
        owner:"Thomason Rentals Llc.",
        link:"/",
        email:"/",
        call:"/",
        beds:"3",
        baths:"4",
        square:"6x8 m²"
      },
    ]
  }
  return (
    <>
      <MobileAdvance />
      <Filters />
      <ResultFilters />
      <ResultProperties data = {listing}/>
    </>
  );
};

export default ResultSearch;
