import React from "react";
import Filters from "./Filters";
import SearchMap from "./Search-map";
import MobileAdvance from "./Mobile-advance";

const SearchProperty = () => {
  return (
    <>
      <MobileAdvance />
      <Filters />
      <SearchMap />
    </>
  );
};

export default SearchProperty;
