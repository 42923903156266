import React,{useState} from "react";
import { Link } from "react-router-dom";
const Form = ({data}) => {

  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    alert(inputs);
  }
  const[isOpen , setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  return (
    <>
      <form onSubmit={handleSubmit}>
          <div className="blk_form">
            <h6>Choose your plan</h6>
            <div className="form_blk">
                <div className="_drop select_drop">
                  <div className="drop_btn flex_drop_select input selec_input" onClick={toggling}>
                    <span>Billed monthly</span><strong>$ 499/mo</strong>
                  </div>
                  <div className={isOpen ? 'drop_cnt active' : 'drop_cnt'}>
                    <ul className="drop_lst">
                      <li className="flex_drop_select input"><span>Billed annualy</span><strong>$ 499/mo</strong></li>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
          <div className="blk_form date_ex_flex">
            <h6>Credit card</h6>
            <Link to="/" className="mini_quest">Why do we need this?</Link>
            <div className="form_blk input">
                <input
                    type="text"
                    
                    onChange={handleChange}
                    placeholder="Card number "
                />
                <input
                    type="text"
                    onChange={handleChange}
                    placeholder="MM / YY  CVC"
                />
            </div>
          </div>
          <h6 className="checkout_sm_heading">Billing address</h6>
            <div className="blk_form">
              <h6>Card holder name</h6>
              <div className="form_blk">
                  <input
                      type="text"
                      className="input"
                      onChange={handleChange}
                      placeholder="Name"
                  />
              </div>
            </div>
            <div className="blk_form">
              <h6>Address</h6>
              <div className="form_blk">
                  <input
                      type="text"
                      className="input"
                      onChange={handleChange}
                      placeholder="Address"
                  />
              </div>
            </div>
            <div className="blk_form">
              <h6>City</h6>
              <div className="form_blk">
                  <input
                      type="text"
                      className="input"
                      onChange={handleChange}
                      placeholder="City"
                  />
              </div>
            </div>
            <div className="flex form_flex_half">
              <div className="blk_form">
                <h6>State</h6>
                <div className="form_blk">
                    <input
                        type="text"
                        className="input"
                        onChange={handleChange}
                        placeholder="state"
                    />
                </div>
              </div>
              <div className="blk_form">
                <h6>Zip code</h6>
                <div className="form_blk">
                    <input
                        type="text"
                        className="input"
                        onChange={handleChange}
                        placeholder="Zip code"
                    />
                </div>
              </div>
            </div>
            <div class="small_txt"><img src="/images/danger_circle.svg" alt="" /><small>You will not be charged until your free trail ended on 10/10/22</small></div>
            <div className="btn_blk">
              <button className="site_btn" type="submit">
              Start my Free Trial
              </button>
            </div>
            <div class="small_txt text-center agree_smll">
              <small>I agree to Terms & condition & subscription agreement</small>
            </div>
            <div className="agree_blk_form">
            <div dangerouslySetInnerHTML={ {__html: data} } />
            </div>
            
      </form>
    </>
  );
};

export default Form;
