import React from "react";
import Card from "./Card";
import Intro from "./Intro";

const Signup = () => {
    const log = {
        card : {
            image:"/images/property.png",
            popLbl:"POPULAR",
            infoLbl:"Also owns",
            infoLnkLbl:"3 other properties",
            infoLnk:"/",
            phone:"+1234567890",
            email:"john@doe.com",
            position:"Owner",
            name:"John Doe",
            btnLbl:"Contact Now",
            btnLnk:"/",
        },
        intro:{
            title1:"Welcome to the sales revolution!",
            detail1:"Rhinos plow through every opportunity with grit and determination. Let's make some sales!",
            title2:"Company info",
            detail2:"Please enter your company information below.",
            title3:"Authenticate your account",
            detail3:"We just sent you a code to validate your email address, check your inbox."
        }
    }
  return (
    <>
      <section className="logon">
        <div className="contain">
            <div className="flex">
                <Intro data = {log.intro}/>
                <div className="colR">
                    <Card data = {log.card}/>
                </div>
            </div>
        </div>
        <div className="lg_image">
            <img src="/images/logon.png" alt="" />
        </div>
      </section>
    </>
  );
};

export default Signup;
