import React from "react";
import Filters from "../search/Filters";
import OwnerFilter from "./Owner-filter";
import TableData from "../search/Table-data";
import MobileAdvance from "../search/Mobile-advance";
const OwnerProperties = () => {
    const searches = {
        data:[
          {
            id:"1",
            address:"Park St, Atlanta, TX 75551",
            last_sale:"30 Dec, 2019",
            sale_price:"$1,250,300",
            year_built:"2018",
            area:"6,070 SF",
            units:"--",
            type:"Multifamily (General)",
            email:"/",
            call:"/"
          },
          {
            id:"2",
            address:"Park St, Atlanta, TX 75551",
            last_sale:"--",
            sale_price:"--",
            year_built:"2018",
            area:"6,070 SF",
            units:"--",
            type:"Multifamily (General)",
            email:"/",
            call:"/"
          },
          {
            id:"3",
            address:"Park St, Atlanta, TX 75551",
            last_sale:"30 Dec, 2019",
            sale_price:"$1,250,300",
            year_built:"--",
            area:"6,070 SF",
            units:"--",
            type:"Multifamily (General)",
            email:"/",
            call:"/"
          }
        ]
       }
  return (
    <>
    <MobileAdvance />
      <Filters />
      <OwnerFilter />
      <section className="owned_property_page with_filter_sec">
        <div className="contain-fluid">
            <h4 className="main_heading">Properties Owned</h4>
            <div className="flex owner_grid">
                <div className="colL">
                    <div className="inner">
                        <h6 className="mini_heading">Property Types</h6>
                        <div className="tiles_flex">
                            <div className="col">
                                <div className="inner">
                                    <p>Multifamily</p>
                                    <h6>$377,620 | 2 Properties</h6>
                                </div>
                            </div>
                            <div className="col">
                                <div className="inner">
                                    <p>Vacant Land</p>
                                    <h6>$47,620 | 1 Property</h6>
                                </div>
                            </div>
                            <div className="col">
                                <div className="inner">
                                    <p>Industrial</p>
                                    <h6>$172,340 | 3 Properties</h6>
                                </div>
                            </div>
                        </div>
                        <div className="br"></div>
                        <h6 className="mini_heading">Key Stats</h6>
                        <div className="key_state_flex flex">
                            <div className="col">
                                <div className="_inner">
                                    <h6>9,703 SF</h6>
                                    <p>Building Area</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="_inner">
                                    <h6>$6,409,471</h6>
                                    <p>Assessed Value</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="_inner">
                                    <h6>01</h6>
                                    <p>Acquisitions in Past Year</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="_inner">
                                    <h6>25 Acres</h6>
                                    <p>Total Lot Area</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="_inner">
                                    <h6>10</h6>
                                    <p>Total Units</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="_inner">
                                    <h6>6 Jan 2022</h6>
                                    <p>Recent Acquisition</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="colR">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.46795716484!2d72.68540231452256!3d32.08363608118693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392177c8976de103%3A0xfd13b9e98e36a9fe!2sHero+Solutions!5e0!3m2!1sen!2s!4v1565165927597!5m2!1sen!2s" width="100%" frameBorder="0" allowFullScreen></iframe>
                </div>
            </div>
        </div>
      </section>
      <TableData data={searches.data}/>
    </>
  );
};

export default OwnerProperties;
