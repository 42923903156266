import React from "react";
import { Link } from "react-router-dom";
import Filters from "../search/Filters";
import OwnerFilter from "./Owner-filter";
import MobileAdvance from "../search/Mobile-advance";
const OwnerCompanies = () => {
    const searches = {
        data:[
          {
            id:"1",
            name:"Antomar of Palm Coast, L.L.C.",
            relationship:"Owner",
            role:"CEO",
            portfolio:"1",
            value:"$1,250,300",
            contact:"1",
            address:"Park St, Atlanta, TX 75551"
          },
          {
            id:"2",
            name:"Amaral Custom Homes, Inc.",
            relationship:"Owner",
            role:"Manager",
            portfolio:"19",
            value:"$1,250,300",
            contact:"2",
            address:"Park St, Atlanta, TX 75551"
          },
          {
            id:"3",
            name:"Antomar of Palm Coast, L.L.C.",
            relationship:"Owner",
            role:"CEO, Director",
            portfolio:"2",
            value:"$1,250,300",
            contact:"2",
            address:"Park St, Atlanta, TX 75551"
          },
          {
            id:"4",
            name:"Antomar of Palm Coast, L.L.C.",
            relationship:"Owner",
            role:"CEO, President",
            portfolio:"78",
            value:"$1,250,300",
            contact:"4",
            address:"Park St, Atlanta, TX 75551"
          },
          {
            id:"5",
            name:"Antomar of Palm Coast, L.L.C.",
            relationship:"Owner",
            role:"CEO, Manager",
            portfolio:"1",
            value:"$1,250,300",
            contact:"1",
            address:"Park St, Atlanta, TX 75551"
          },
          {
            id:"6",
            name:"Antomar of Palm Coast, L.L.C.",
            relationship:"Owner",
            role:"CEO",
            portfolio:"1",
            value:"$1,250,300",
            contact:"1",
            address:"Park St, Atlanta, TX 75551"
          },
        ]
       }
  return (
    <>
    <MobileAdvance />
      <Filters />
      <OwnerFilter />
      <section className="table_view_sec companies_table_page with_filter_sec">
        <div className="contain-fluid">
            <h4 className="main_heading">Related Companies</h4>
            <div className="inner_tbl">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Relationship</th>
                            <th>Role/ Title</th>
                            <th>Portfolio Properties</th>
                            <th>Portfolio Assessed Value</th>
                            <th>Contact</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searches.data?.map((val,i)=>{
                            return(
                                <tr key={i}>
                                    <td>{val.name}</td>
                                    <td>{val.relationship}</td>
                                    <td>{val.role}</td>
                                    <td>{val.portfolio}</td>
                                    <td>{val.value}</td>
                                    <td>{val.contact}</td>
                                    <td>{val.address}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="pagination_blk">
                    <div className="colL">
                        <span>1－10 of 57</span>
                    </div>
                    <div className="colR">
                        <div className="pagination_slider">
                            <span>1 of 6</span>
                            <button className="perv_btn"><img src="/images/left_arrow.svg" alt="" /></button>
                            <button className="next_btn"><img src="/images/left_arrow.svg" alt="" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default OwnerCompanies ;
