import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Outlet } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
// Common Pages
import Header from "./components/includes/Header";
import Footer from "./components/includes/Footer";
import Home from "./components/pages/home/Index";
import Login from "./components/pages/login/Index";
import Signup from "./components/pages/signup/Index";
import Pricing from "./components/pages/pricing/Index";
import Blog from "./components/pages/blog/Index";
import BlogDetails from "./components/pages/blog/Blog-details";
import About from "./components/pages/about/Index";
import Help from "./components/pages/help/Index";
import UploadData from "./components/pages/upload/Index";
import EnterpriseCheckout from "./components/pages/enterprise-form/Index";
import Checkout from "./components/pages/checkout/Index";
import SearchFound from "./components/pages/help/Search-found";
import SearchArticle from "./components/pages/help/Search-article";
import PrivacyPolicy from "./components/pages/blank-page/Index";
import AccountSettings from "./components/pages/account-settings/Index";
import SearchProperty from "./components/pages/search/Index";
import PropertyListing from "./components/pages/property/Index";
import ResultSearch from "./components/pages/search/Result-search";
import SavedSearches from "./components/pages/saved-searches/Index";
import SearchTable from "./components/pages/search/Search-table";
import Export from "./components/pages/export/Index";
import PropertyDetails from "./components/pages/search/Property-details";
import OwnerProperties from "./components/pages/owner-property/Index";
import OwnerCompanies from "./components/pages/owner-property/Owner-companies";
import OwnerOverview from "./components/pages/owner-property/Owner-overview";
import Support from "./components/pages/support/Index";
import { Navigate } from "react-router-dom";

function App() {
  
  const PageLayout = () => (
    <>
      <Header logged={false} />
      <Outlet />
      <Footer />
    </>
  );

  const PageLogLayout = () => (
    <>
      <Header logged={true} />
      <Outlet />
    </>
  );

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PageLayout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/blogs" element={<Blog />} />
            <Route exact path="/blog-detail/:id" element={<BlogDetails />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/support" element={<Support />} />
            <Route exact path="/help" element={<Help />} />
            <Route exact path="/upload-data" element={<UploadData />} />
            <Route exact path="/enterprise-checkout" element={<EnterpriseCheckout />} />
            <Route exact path="/checkout" element={<Checkout />} />
            <Route exact path="/help-result" element={<SearchFound />} />
            <Route exact path="/question-article" element={<SearchArticle />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/property-listing" element={<PropertyListing />} />
            <Route exact path="/save-searches" element={<SavedSearches />} />
          </Route>
          
        </Routes>
        <Routes>
          <Route element={<PageLogLayout />}>
            <Route exact path="/account-settings" element={<AccountSettings />} />
            <Route exact path="/search-property" element={<SearchProperty />} />
            <Route exact path="/result-search" element={<ResultSearch />} />
            <Route exact path="/search-table" element={<SearchTable />} />
            <Route exact path="/export" element={<Export />} />
            <Route exact path="/property-details" element={<PropertyDetails />} />
            <Route exact path="/owner-property" element={<OwnerProperties />} />
            <Route exact path="/owner-companies" element={<OwnerCompanies />} />
            <Route exact path="/owner-overview" element={<OwnerOverview />} />
          </Route>
        </Routes>
        
        {/* {state.PopupRegister === true ? <PopupRegister close={closePopupRegister} /> : ""} */}
      </BrowserRouter>
    </>
  );
}

export default App;
