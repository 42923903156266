import React from "react";
import { Link } from "react-router-dom";

const MonthlyPlan = ({data}) => {
  return (
    <>
      {data?.map((val , i) => {
        return(
            <div className="col" key={i}>
                <div className="inner">
                <div className="head_pricing">
                    <h5>{val.type}</h5>
                    <p>{val.lbl}</p>
                </div>
                <div className="price_body">
                    <div dangerouslySetInnerHTML={ {__html: val.listing} } />
                </div>
                <div className="price_footer">
                    <h6>{val.duration}</h6>
                    <h4>{val.price}</h4>
                    <Link to={val.btnLnk} className="site_btn blank">
                    {val.btnLbl}
                    </Link>
                    <div className="text-center mini_lbl">
                      {val.mini_lbl}
                    </div>
                </div>
                </div>
            </div>
        );
      })}
    </>
  );
};

export default MonthlyPlan;
