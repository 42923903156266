import React, { useEffect , useState} from "react";
import TabContent from "./Tab-content";
function Banner({ data }) {
  // const [currentTab, setCurrentTab] = useState('1');
  // const handleTabClick = (e) => {
  //   setCurrentTab(e.target.id);
  // }
  return (
    <>
      <section className="banner hide_cell_cnt_banner">
        <div
          >
          <div className="item">
            <div className="videoBanner">
                <video preload="yes" autoPlay muted playsInline loop src="/images/banner_video.mp4" width="100%" height="100%">
                    <source src="/images/banner_video.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="contain">
              <div className="flex">
                <div className="colL">
                  <div className="cntnt">
                    <h1>{data.heading}</h1>
                  </div>
                  <div className="flex">
                  {data.stats?.map((val, index) => {
                    return(
                    <div className="_col" key={index}>
                      <div className="inner" >
                        <h4 className="color">{val.number}</h4>
                        <p>{val.title}</p>
                      </div>
                    </div>
                    );
                  })}
                  </div>
                  <div className="pera">
                    <p>{data.text}</p>
                  </div>
                  <div className="banner_form">
                    {/* <ul className="nav nav-tabs">
                      {data.tabs?.map((tab , i) =>{
                        return(
                          <li key={i}>
                            <button key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={(handleTabClick)}>{tab.tabTitle}</button>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="tab-content">
                      {data.tabs?.map((tab , i) =>{
                        return(
                          <div key={i}>
                            {currentTab === `${tab.id}` && <div className="flex ban_flex">{tab.content}</div>}
                          </div>
                        );
                      })}
                    </div> */}
                    <div className="sm_form_ban">
                    <div className="flex ban_flex"><TabContent /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </section>

      <section className="banner show_cell_banner">
        <div
          >
          <div className="item">
            <div className="contain">
              <div className="flex">
                <div className="colL">
                  <div className="flex">
                  {data.stats?.map((val, index) => {
                    return(
                    <div className="_col" key={index}>
                      <div className="inner" >
                        <h4 className="color">{val.number}</h4>
                        <p>{val.title}</p>
                      </div>
                    </div>
                    );
                  })}
                  </div>
                  <div className="banner_form">
                    <div className="sm_form_ban">
                    <div className="flex ban_flex"><TabContent /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </>
  );
}

export default Banner;
