import React,{useState} from "react";
import Strip from "./Strip";
import SideBar from "./Side-bar";
import Profile from "./Profile";
import MyAccount from "./My-account";
import Notifications from "./Notifications";
import Exports from "./Exports";
import Billing from "./Billing";
import MembershipManagement from "./Membership-Management";

const AccountSettings = () => {
  const [page , setPage] = useState('Profile');
  const [mobActive , setMobActive] = useState(false);
  const togglePage = (pagename) =>{
    // console.log(pagename);
    setPage(pagename);
    setMobActive(!mobActive);
  }
  const ApplyActive = () => {
    setMobActive(!mobActive);
  }

  return (
    <>
    <section className="account_settings">
      <Strip activePage = {page} ActiveApply = {ApplyActive}/>
      <div className="contain-fluid">
        <div className="flex multi_components">
          <div className="colL">
              <SideBar pageToggle = {togglePage} activePage = {page}/>
          </div>
          <div className={mobActive ? "colR active" : "colR"}>
              {page === 'Profile' && <Profile/>}
              {page === 'MyAccount' && <MyAccount/>}
              {page === 'Notifications' && <Notifications/>}
              {page === 'Exports' && <Exports/>}
              {page === 'Billing' && <Billing/>}
              {page === 'MembershipManagement' && <MembershipManagement/>}
          </div>
        </div>
      </div>
    </section>
      
    </>
  );
};

export default AccountSettings;